<template>
    <div>
        <div class="firm-panel">
            <h3 class="g-title">极客简介</h3>
            <div
                class="introduction"
            >
                <div>
                    <div v-if="value.attestation_info && value.attestation_info.introduction" v-html="value.attestation_info.introduction"></div>
                    <div v-else style="width:100%;height:300px;text-align:center;padding-top:100px;color:#999;font-size:14px;">未填写极客简介</div>
                </div>
            </div>
        </div>
        <div class="firm-panel" style="border:none;">
            <h3 class="g-title">能力标签</h3>
            <div
                class="tag-wrap"
                v-if="value.attestation_info && value.attestation_info.ability_tags"
            >
                <span
                    v-for="(item,index) in value.attestation_info.ability_tags"
                    :key="index"
                >{{item}}</span>
            </div>
            <div v-else style="width:100%;height:300px;text-align:center;padding-top:100px;color:#999;font-size:14px;">未设置能力标签</div>
        </div>
    </div>
</template>

<script>
import "quill/dist/quill.snow.css";
export default {
    name: "summaryIndex",
    props: ['value'],
};
</script>





